import { Get, Put, Post, Delete, Patch } from "./api.helper";
import { store } from "../store";
import { handleFetchError } from "../utils/errorHandler";
import Config from "../Config";
import { appendQueryParams } from "../utils/urlUtils";

// ==== Authentication
export const Login = (bodyData) => {
    return Post({
        url: "/auth/login",
        body: bodyData,
        isAuthorized: false
    });
};

export const GetMe = () => {
    return Get({ url: "/user/me" });
};

export const ForgotPassword = (bodyData) => {
    return Post({ url: "/auth/forget-password", body: bodyData });
};

export const ForgotPasswordVerification = (bodyData) => {
    return Post({
        url: "/auth/forget-password/verification",
        body: bodyData
    });
};

export const ResetPassword = (bodyData) => {
    return Post({ url: "/auth/reset-password", body: bodyData });
};

export const VerifyUser = (bodyData) => {
    return Post({
        url: "/",
        body: bodyData,
        isAuthorized: false
    });
};

export const SignUp = (bodyData) => {
    return Post({
        url: "/auth/signup",
        body: bodyData,
        isAuthorized: false
    });
};

//users
export const UpdateUserById = ({ Id, bodyData }) => {
    return Patch({ url: `/user/${Id}/profile/status/update`, body: bodyData });
};

export const UpdateProviderById = ({ Id, bodyData }) => {
    return Patch({ url: `/user/${Id}/profile/status/update`, body: bodyData });
};

export const DeleteUserById = ({ Id }) => {
    return Delete({ url: `/user/${Id}/delete-account` });
};

//Dashboard

export const GetUsersAnalytics = ({ params = {}, abortSignal = null }) => {
    return Get({ url: "/dashboard/users-overview", params, abortSignal });
};
export const GetJobsAnalytics = ({ params = {}, abortSignal = null }) => {
    return Get({ url: "/dashboard/job-overview", params, abortSignal });
};

//Provider Management

export const GetProviders = ({ params = {}, abortSignal = null }) => {
    return Get({ url: "/user/providers", params, abortSignal });
};

export const GetProviderById = ({ Id, params = {}, abortSignal = null }) => {
    return Get({ url: `/user/${Id}/provider/profile`, params, abortSignal });
};
export const GetJobStatsById = ({ Id, params = {}, abortSignal = null }) => {
    return Get({ url: `/user/${Id}/job-stats`, params, abortSignal });
};

export const GetPromotionStats = ({ id, params = {}, abortSignal = null }) => {
    return Get({ url: `/promotion/listing`, params, abortSignal });
};

export const GetRatingListing = ({ params = {}, abortSignal = null }) => {
    return Get({ url: "/job-rating/listing", params, abortSignal });
};

//Seekers Management
export const GetSeekers = ({ params = {}, abortSignal = null }) => {
    return Get({ url: "/user/seekers", params, abortSignal });
};

export const GetSeekerById = ({ Id, params = {}, abortSignal = null }) => {
    return Get({ url: `/user/${Id}/seeker/profile`, params, abortSignal });
};

export const GetCareProfiles = ({ params = {}, abortSignal = null }) => {
    return Get({ url: "/user/care-profiles", params, abortSignal });
};

//Category Management
export const GetCategories = ({ params = {}, abortSignal = null }) => {
    return Get({ url: "/categories", params, abortSignal });
};

export const GetCategoryById = ({ id, params = {}, abortSignal = null }) => {
    return Get({ url: `/${id}`, params, abortSignal });
};

export const UpdateCategoryById = ({ Id, bodyData }) => {
    return Patch({ url: `/${Id}`, body: bodyData });
};

export const UpdateSubCategoryById = ({ id, subTypeId, bodyData }) => {
    return Patch({ url: `/${id}/categorySubType/${subTypeId}`, body: bodyData });
};

//Job Listing
export const GetJobs = ({ params = {}, abortSignal = null }) => {
    return Get({ url: "/jobs/listing", params, abortSignal });
};

export const GetJobById = ({ Id, params = {}, abortSignal = null }) => {
    return Get({ url: `/jobs/${Id}`, params, abortSignal });
};

export const DeleteJobById = (Id) => {
    return Delete({ url: `/jobs/${Id}` });
};

//Guest User Management

export const GetUsers = ({ params = {}, abortSignal = null }) => {
    return Get({ url: "/user/guest-users", params, abortSignal });
};

// Account

export const ChangePasswordAccount = ({ id, bodyData }) => {
    return Patch({ url: `/user/${id}`, body: bodyData });
};

//Pured -  web

export const GetUserById = (Id, params = {}, abortSignal = null) => {
    return Get({ url: `/user/${Id}`, params, abortSignal });
};

export const UpdateUser = ({ id, bodyData }) => {
    return Put({ url: `/user/customer/${id}`, body: bodyData });
};

export const MediaUpload = (bodyData, abortSignal = null) => {
    const token = store.getState().userAuth.user.Token;

    const url = `${Config.env().API_URL}/media/upload`;

    return fetch(url, {
        headers: {
            authorization: token
        },
        body: bodyData,
        method: "POST"
    }).then(handleFetchError);
};
