import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";

import viewIcon from "../../assets/images/app/common/listing-view-image.svg";

import { SearchBox } from "../../components/SearchBox";
import { Pagination } from "../../components/Pagination";
import { DataTable } from "../../components/DataTable";
import { APP_ROUTES, getParamsAttachedRoute } from "../../helpers/routeHelpers";
import { GetJobs } from "../../api/api.service";
import { MODULE_KEYS, getKey } from "../../api/api.keys";
import { useLocalPaginatedRequest } from "../../hooks/useLocalPaginatedRequest";
import { JobsStatus, PageTitles } from "../../constants";
import { monthYear } from "../../utils/dateUtils";
import { AutoComplete } from "../../components/AutoComplete";
import { customStyles } from "./CustomStyles";
import { formatKey, truncateString } from "../../utils/commonUtils";
import { CATEGORY_TYPE, CATEGORY_IDS } from "../../constants/CategoryType";
import { dateTimeFormat } from "../../utils/dateFormatUtils";

const COLUMNS = {
    JOB_TITLE: "Job Title",
    CATEGORY: "Category",
    SEEKER_NAME: "Seeker Name",
    POSTED_DATE: "Posted Date",
    HIRING_DATE: "Hiring Date",
    CURRENT_STATUS: "Current Status",
    LOCATION: "Location",
    JOB_ID: "Job ID",
    JOB_TYPE: "Job Type",
    ACTIONS: "Actions"
};

const PROVIDER = "PROVIDER";

const categoryOptions = Object.keys(CATEGORY_TYPE).map((key) => ({
    label: formatKey(CATEGORY_TYPE[key]), // This is what the user sees in the dropdown
    value: CATEGORY_IDS[key] // This is the value you pass to the backend
}));

const JobListing = ({}) => {
    const history = useHistory();
    const [selectedOption, setSelectedOption] = useState(null);

    const {
        isFetching,
        page,
        total,
        data,
        limit,
        searchText,
        handlePageClick,
        handleSearchChange,
        handleSortingChange,
        setPageNumberOne
    } = useLocalPaginatedRequest({
        key: getKey([MODULE_KEYS.JOB_MANAGEMENT_LISTING]),
        requestFn: GetJobs,
        dataKey: "data",
        totalKey: "total",
        params: {
            type: PROVIDER,
            ...(selectedOption?.length > 0 && {
                categoryIds: JSON.stringify(selectedOption.map((value) => value.value))
            })
        }
    });

    const handleChange = (selected) => {
        setPageNumberOne();
        setSelectedOption(selected);
    };

    return (
        <div className="page-content">
            <Helmet>
                <title>{PageTitles.JOB_LISTING}</title>
            </Helmet>
            <Container fluid>
                <Row>
                    <Col sm="12" md="3" lg="3">
                        <SearchBox
                            className="me-3 w-50"
                            value={searchText}
                            onChange={handleSearchChange}
                        />
                    </Col>
                    <Col sm="12" md="6" lg="6"></Col>
                    <Col sm="12" md="3" lg="3">
                        <AutoComplete
                            isMulti
                            customStyles={customStyles.providerFilter}
                            options={categoryOptions}
                            onChange={handleChange}
                            value={selectedOption?.label}
                            placeholder="Filter by Category"
                            isClearable
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: "#2795ae"
                                }
                            })}
                            maxMenuHeight={220}
                            classNamePrefix="status-header-search-admin"
                        />
                    </Col>
                </Row>

                <DataTable
                    tableContainerClass="mt-3"
                    loading={isFetching}
                    data={data}
                    config={[
                        {
                            title: COLUMNS.JOB_ID,
                            render: (data) => <div>{data?.id || "-"}</div>
                        },
                        {
                            title: COLUMNS.JOB_TITLE,
                            render: (data) => <div>{truncateString(data?.title, 30) || "-"}</div>
                        },
                        {
                            title: COLUMNS.CATEGORY,
                            render: (data) => <div>{formatKey(data?.category?.name) || "-"}</div>
                        },
                        {
                            title: COLUMNS.SEEKER_NAME,
                            render: (data) => (
                                // <div>{truncateString(data?.assignee?.name, 30) || "-"}</div>
                                <div>{truncateString(data?.user?.name, 30) || "-"}</div>
                            )
                        },
                        {
                            title: COLUMNS.POSTED_DATE,
                            render: (data) =>
                                monthYear(dateTimeFormat.appDateFormat, data?.createdAt) || "-"
                        },
                        {
                            title: COLUMNS.HIRING_DATE,
                            render: (data) => (
                                <div>
                                    {monthYear(dateTimeFormat.appDateFormat, data?.assignedAt) ||
                                        "-"}
                                </div>
                            )
                        },
                        {
                            title: COLUMNS.CURRENT_STATUS,
                            render: (data) => (
                                <div
                                    className={`job-status-common ${JobsStatus.getLabelClass(
                                        data?.status
                                    )}`}
                                >
                                    {JobsStatus.getDisplayTextKey(data?.status) || "-"}
                                </div>
                            )
                        },

                        {
                            title: COLUMNS.JOB_TYPE,
                            render: (data) => <div>{data?.jobType || "-"}</div>
                        },
                        {
                            title: COLUMNS.ACTIONS,
                            render: (data) => (
                                <img
                                    style={{
                                        filter: "brightness(0) saturate(100%) invert(58%) sepia(21%) saturate(708%) hue-rotate(283deg) brightness(83%) contrast(82%)"
                                    }}
                                    className="me-3"
                                    src={viewIcon}
                                    alt={"details icon"}
                                    role="button"
                                    onClick={() =>
                                        history.push(
                                            getParamsAttachedRoute(APP_ROUTES.JOB_LISTING_DETAIL, {
                                                jobId: data?.id
                                            })
                                        )
                                    }
                                />
                            )
                        }
                    ]}
                />
                <Pagination
                    rowLimit={limit}
                    onPageClick={handlePageClick}
                    currentPage={page}
                    totalData={total}
                    handleChange={(e) => handleSortingChange(e.target.value)}
                />
            </Container>
        </div>
    );
};

export default JobListing;
