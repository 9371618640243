import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardTitle, CardText, Row, Col } from "reactstrap";

const SummaryCard = ({
    title,
    titleTag,
    titleClassName,
    children,
    colProps,
    cardProps,
    cardBodyProps,
    cardTextProps,
    colClassName
}) => {
    return (
        <Col {...colProps} className={colClassName}>
            <Card {...cardProps} className="custom-card p-3">
                <CardBody {...cardBodyProps}>
                    {title && (
                        <CardTitle tag={titleTag} className={titleClassName}>
                            {title}
                        </CardTitle>
                    )}
                    <CardText {...cardTextProps}>{children}</CardText>
                </CardBody>
            </Card>
        </Col>
    );
};

SummaryCard.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    titleTag: PropTypes.string,
    titleClassName: PropTypes.string,
    colClassName: PropTypes.string,
    children: PropTypes.node.isRequired,
    colProps: PropTypes.object,
    cardProps: PropTypes.object,
    cardBodyProps: PropTypes.object,
    cardTextProps: PropTypes.object
};

SummaryCard.defaultProps = {
    title: null,
    titleTag: "h3",
    colClassName: "my-1",
    titleClassName: "mb-0",
    colProps: { xxl: 12, md: 12 },
    cardProps: { className: "p-2" },
    cardBodyProps: { className: "p-0" },
    cardTextProps: {}
};

export default SummaryCard;
