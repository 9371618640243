import React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { CardTitle, Col, Container, Row } from "reactstrap";

import { DataTable } from "../../components/DataTable";
import { SummaryCard } from "../../components/SummaryCard";
import { LabeledDataItem } from "../../components/LabeledDataItem";
import { useRequestProcessor } from "../../hooks/useRequestProcessors";
import { getKey, MODULE_KEYS } from "../../api/api.keys";
import { GetCareProfiles } from "../../api/api.service";
import { formatKey, hasData, truncateString } from "../../utils/commonUtils";
import { LoaderFallbackUI } from "../../components/LoaderFallbackUI";
import { Pagination } from "../../components/Pagination";
import { useLocalPaginatedRequest } from "../../hooks/useLocalPaginatedRequest";
import { monthYear } from "../../utils/dateUtils";
import { SearchBox } from "../../components/SearchBox";

const COLUMNS = {
    S_NO: "S.No",
    NAME: "Name",
    PET_NAME: "Pet Name",
    PET_TYPE: "Pet Type",
    AGE: "Age",
    AGE_GROUP: "Age Group",
    GENDER: "Gender",
    DOB: "Date of birth",
    TYPE: "Type"
};

const childCareColumns = [
    { title: COLUMNS.NAME, render: (data) => data?.name || "-" },
    { title: COLUMNS.AGE_GROUP, render: (data) => data?.childCareProfile?.ageGroup || "-" },
    { title: COLUMNS.GENDER, render: (data) => data?.childCareProfile?.gender || "-" }
];

const seniorCareColumns = [
    { title: COLUMNS.NAME, render: (data) => data?.name || "-" },
    { title: COLUMNS.DOB, render: (data) => data?.dateOfBirth || "-" },
    { title: COLUMNS.GENDER, render: (data) => data?.gender || "-" }
];

const petCareColumns = [
    { title: COLUMNS.PET_NAME, render: (data) => data?.petName || "-" },
    { title: COLUMNS.PET_TYPE, render: (data) => data.petType || "-" },
    { title: COLUMNS.AGE, render: (data) => data.age || "-" }
];

const Detail_CareProfile = () => {
    const { seekerId } = useParams();
    const { useFetch } = useRequestProcessor();

    const {
        isFetching,
        page,
        total,
        isLoading,
        data,
        limit,
        searchText,
        handlePageClick,
        handleSearchChange,
        handleSortingChange
    } = useLocalPaginatedRequest({
        key: getKey([MODULE_KEYS.SEEKER_CARE_PROFILE]),
        dataKey: "data",
        totalKey: "total",

        requestFn: GetCareProfiles,
        params: { userId: seekerId }
    });

    // Filter the data for ChildCare, SeniorCare, and PetCare
    let childCareData = data ? data.filter((care) => care.type === "ChildCare") : [];
    let seniorCareData = data ? data.filter((care) => care.type === "SeniorCare") : [];
    let petCareData = data ? data.filter((care) => care.type === "PetCare") : [];

    if (isFetching || isLoading || !hasData(data)) {
        return (
            <div style={{ height: "70vh" }}>
                <LoaderFallbackUI isLoader={isFetching} className={"h-100"} />
            </div>
        );
    }

    return (
        <div className="mt-4">
            <Helmet>
                <title>Seeker Management</title>
            </Helmet>
            <Container fluid>
                {/* <Row>
                    <Col sm="12" md="3" lg="3"></Col>
                    <Col sm="12" md="6" lg="6"></Col>
                    <Col sm="12" md="3" lg="3">
                        <SearchBox
                            className="me-3 w-50"
                            value={searchText}
                            onChange={handleSearchChange}
                        />
                    </Col>
                </Row> */}
                {/* <DataTable
                    tableContainerClass="mt-3"
                    loading={isFetching}
                    data={data}
                    config={[
                        {
                            title: COLUMNS.S_NO,
                            render: (data) => data?.id || "-"
                        },

                        {
                            title: COLUMNS.NAME,
                            render: (data) => truncateString(data.name, 30) || "-"
                        },

                        {
                            title: COLUMNS.AGE_GROUP,
                            render: (data) =>
                                <div>{truncateString(data?.childCareProfile?.ageGroup, 30)}</div> ||
                                "-"
                        },
                        {
                            title: COLUMNS.GENDER,
                            render: (data) => data?.childCareProfile?.gender || "-"
                        },
                        {
                            title: COLUMNS.DOB,

                            render: (data) => monthYear("DD/MM/YY ", data.dateOfBirth) || "-"
                        },
                        {
                            title: COLUMNS.TYPE,
                            render: (data) => formatKey(data.type) || "-"
                        }
                    ]}
                /> */}

                <Pagination
                    rowLimit={limit}
                    onPageClick={handlePageClick}
                    currentPage={page}
                    totalData={total}
                    handleChange={(e) => handleSortingChange(e.target.value)}
                />
            </Container>

            <SummaryCard title="Child Care Profiles Overview">
                <Row>
                    <DataTable
                        loading={isFetching}
                        tableContainerClass="my-3"
                        data={childCareData}
                        config={childCareColumns}
                    />
                </Row>
            </SummaryCard>
            {petCareData?.length > 0 && (
                <SummaryCard title="Senior Care Profiles Overview">
                    <Row>
                        <DataTable
                            loading={isFetching}
                            tableContainerClass="my-3"
                            data={seniorCareData}
                            config={seniorCareColumns}
                        />
                    </Row>
                </SummaryCard>
            )}
            {petCareData?.length > 0 && (
                <SummaryCard title="Pet Care Profiles Overview">
                    <Row>
                        <DataTable
                            loading={isFetching}
                            tableContainerClass="my-3"
                            data={petCareData}
                            config={petCareColumns}
                        />
                    </Row>
                </SummaryCard>
            )}

            <Pagination
                rowLimit={limit}
                onPageClick={handlePageClick}
                currentPage={page}
                totalData={total}
                handleChange={(e) => handleSortingChange(e.target.value)}
            />
        </div>
    );
};

export default Detail_CareProfile;
