import { injectBaseConstantMethods } from "./BaseConstants";

export const JOBS_STATUS = {
    NORMAL_HIRE: "NORMAL_HIRE", // done
    ACTIVE: "ACTIVE", // in progress
    COMPLETED: "COMPLETED", // done
    CANCELLED_BY_PROVIDER: "CANCELLED_BY_PROVIDER", // canceled
    CANCELLED_BY_SEEKER: "CANCELLED_BY_SEEKER", // canceled
    EXPIRED: "EXPIRED", // expired
    IN_PROGRESS: "IN_PROGRESS"
};

const displayTextKeys = {
    [JOBS_STATUS.NORMAL_HIRE]: "Normal Hire",
    [JOBS_STATUS.ACTIVE]: "Active",
    [JOBS_STATUS.COMPLETED]: "Completed",
    [JOBS_STATUS.CANCELLED_BY_PROVIDER]: "Cancelled",
    [JOBS_STATUS.CANCELLED_BY_SEEKER]: "Cancelled",
    [JOBS_STATUS.EXPIRED]: "Expired",
    [JOBS_STATUS.IN_PROGRESS]: "In Progress"
};

const labelClass = {
    [JOBS_STATUS.NORMAL_HIRE]: "cs-badge-normal-hire",
    [JOBS_STATUS.ACTIVE]: "cs-badge-active",
    [JOBS_STATUS.COMPLETED]: "cs-badge-completed",
    [JOBS_STATUS.CANCELLED_BY_PROVIDER]: "cs-badge-cancelled",
    [JOBS_STATUS.CANCELLED_BY_SEEKER]: "cs-badge-cancelled",
    [JOBS_STATUS.EXPIRED]: "cs-badge-expired",
    [JOBS_STATUS.IN_PROGRESS]: "cs-badge-in-progress"
};

export default injectBaseConstantMethods(JOBS_STATUS, displayTextKeys, labelClass);
