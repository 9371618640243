import React, { useState, useCallback } from "react";
import { Col, Row } from "reactstrap";
import { useParams, useHistory } from "react-router-dom";

import deleteModalImg from "../../assets/images/app/common/modal-delete-img.svg";

import { HeaderBackButton } from "../../components/HeaderBackButton";
import { CustomButton } from "../../components/CustomButton";
import { JobsStatus } from "../../constants";
import { ConfirmationModal } from "../../components/CustomModal";
import { useRequestProcessor } from "../../hooks/useRequestProcessors";
import { getKey } from "../../api/api.keys";
import { DeleteJobById } from "../../api/api.service";
import { showToast, SUCCESS } from "../../utils/toastUtils";
import { APP_ROUTES } from "../../helpers/routeHelpers";

// Use constants for actions to avoid magic numbers and strings
const ACTIONS = {
    ACTIVATE: 1,
    DEACTIVATE: 0,
    DELETE: 2
};

// Configuration object for the modal
const MODAL_CONTENT_CONFIG = {
    [ACTIONS.DELETE]: {
        modalTitle: "Are you sure you want to delete this job?",
        yesButtonText: "Yes",
        noButtonText: "Cancel",
        imageSrc: deleteModalImg
    }
};

const JobDetailHeader = ({ status }) => {
    const history = useHistory();
    const { jobId } = useParams();
    const [confirmationModalConfig, setConfirmationModalConfig] = useState(null);
    const [isDeletingJob, setIsDeletingJob] = useState(false);

    // Use a custom hook to handle API calls and mutations
    const { useMutate } = useRequestProcessor();
    const { mutate: deleteJobMutate } = useMutate("", getKey(), (data) => DeleteJobById(jobId));

    // Handler for delete button click
    const handleDelete = useCallback(() => {
        setConfirmationModalConfig(MODAL_CONTENT_CONFIG[ACTIONS.DELETE]);
    }, []);

    // Handle deletion confirmation and execute the mutation
    const handleDeleteModalConfirmation = useCallback(() => {
        setIsDeletingJob(true);
        deleteJobMutate(
            {},
            {
                onSuccess: () => {
                    setIsDeletingJob(false);
                    showToast(SUCCESS, "Job has been deleted successfully");
                    setConfirmationModalConfig(null);
                    history.push(APP_ROUTES.JOB_LISTING);
                },
                onError: (error) => {
                    setIsDeletingJob(false);
                    console.error("Deletion failed:", error);
                    // Handle error with a toast or fallback UI
                }
            }
        );
    }, [deleteJobMutate, history]);

    // Handle cancellation of any modal action
    const handleActionCancellation = useCallback(() => {
        setConfirmationModalConfig(null);
    }, []);

    return (
        <>
            <Row className="d-flex align-items-center">
                <Col sm="12" md="3" lg="3">
                    <HeaderBackButton />
                </Col>
                <Col sm="12" md="4" lg="4"></Col>
                <Col sm="12" md="5" lg="5">
                    <div className="d-flex align-items-center justify-content-end">
                        <div
                            className={`job-header-status  me-4 ${JobsStatus.getLabelClass(
                                status
                            )}`}
                        >
                            {JobsStatus.getDisplayTextKey(status) || "-"}
                        </div>
                        {/* <div className="profile-header-status me-4">

                            {status}
                        </div> */}
                        <div className="user-management-delete-container">
                            <CustomButton
                                title="Delete"
                                className="custom-btn-action-red"
                                onClick={handleDelete}
                                type="button"
                            />
                        </div>
                    </div>
                </Col>
            </Row>

            {/* Render the confirmation modal if it is configured */}
            {confirmationModalConfig && (
                <ConfirmationModal
                    size="md"
                    hasCustomConfig
                    isOpen={!!confirmationModalConfig}
                    customImageSrc={confirmationModalConfig.imageSrc}
                    customTitle={confirmationModalConfig.modalTitle}
                    customYesBtnText={confirmationModalConfig.yesButtonText}
                    customNoBtnText={confirmationModalConfig.noButtonText}
                    onYes={handleDeleteModalConfirmation}
                    onNo={handleActionCancellation}
                    yesLoading={isDeletingJob}
                    imageStyle={{
                        filter: "brightness(0) saturate(100%) invert(58%) sepia(21%) saturate(708%) hue-rotate(283deg) brightness(83%) contrast(82%)"
                    }}
                />
            )}
        </>
    );
};

export default JobDetailHeader;
