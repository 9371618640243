import React from "react";
import { Table } from "reactstrap";
import { withTranslation } from "react-i18next";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import TableHead from "./TableHead";
import { Loader } from "../../components/Loader";

const DataTable = (props) => {
    const {
        config,
        data,
        onRowClick,
        onRowDrag,
        currentSortOrder,
        currentSortColumn,
        onSortClick,
        loading = false,
        tableContainerClass = "",
        isHeightRequired = false
    } = props;

    const isRowDraggable = () => {
        return onRowDrag && typeof onRowDrag === "function";
    };

    const isRowClickable = () => {
        return onRowClick && typeof onRowClick === "function";
    };

    const handleRowClick = (e, dataObj) => {
        if (isRowClickable()) {
            onRowClick(e, dataObj);
        }
    };

    const shouldShowCol = (data) => {
        return !data.hasOwnProperty("show") || data.show;
    };

    return (
        <DragDropContext onDragEnd={onRowDrag}>
            <div className={`table-responsive ${isHeightRequired && "table-min-height"}`}>
                <Table
                    className={`table-centered table-nowrap app-data-table ${tableContainerClass}`}
                >
                    <thead className="thead-light">
                        <tr>
                            {config.map((item, index) => {
                                return (
                                    shouldShowCol(item) && (
                                        <TableHead
                                            key={index}
                                            options={item}
                                            currentSortOrder={currentSortOrder}
                                            currentSortColumn={currentSortColumn}
                                            onSortClick={onSortClick}
                                        />
                                    )
                                );
                            })}
                        </tr>
                    </thead>
                    <Droppable droppableId="table" isDropDisabled={!isRowDraggable()}>
                        {(provided) => (
                            <tbody ref={provided.innerRef} {...provided.droppableProps}>
                                {data &&
                                    data.length > 0 &&
                                    !loading &&
                                    data.map((dataObj, dataIndex) => {
                                        return (
                                            <Draggable
                                                key={dataObj.Id}
                                                draggableId={`${dataObj.Id}`}
                                                index={dataIndex}
                                                isDragDisabled={!isRowDraggable()}
                                            >
                                                {(provided) => (
                                                    <tr
                                                        key={dataIndex}
                                                        onClick={(e) => handleRowClick(e, dataObj)}
                                                        className={`${
                                                            isRowClickable() ? "cursor-pointer" : ""
                                                        }`}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        {config.map((item, index) => {
                                                            return (
                                                                shouldShowCol(item) && (
                                                                    <td
                                                                        key={index}
                                                                        className={item.className}
                                                                    >
                                                                        {item.render(
                                                                            dataObj,
                                                                            dataIndex
                                                                        )}
                                                                    </td>
                                                                )
                                                            );
                                                        })}
                                                    </tr>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                {provided.placeholder}
                            </tbody>
                        )}
                    </Droppable>
                </Table>
                {(!data || !data.length || loading) && (
                    <div className="table-loader-container">
                        {loading ? (
                            <Loader />
                        ) : (
                            <p className="text-muted text-center">{"No Data Available"}</p>
                        )}
                    </div>
                )}
            </div>
        </DragDropContext>
    );
};

export default withTranslation()(DataTable);
