// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-header-status {
  background-color: #f7e5ec;
  padding: 0.5rem 0.8rem;
  border-radius: 30px;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid #000000;
  min-width: 150px;
  display: flex;
  justify-content: center;
}

.profile-header-text {
  font-size: 14px;
  color: #000000;
  font-weight: 600;
  text-decoration: underline;
}

.hr-classes {
  border-top-width: 2px;
  opacity: 2;
  color: black;
}

.user-management-details-option-container {
  display: flex;
  justify-content: flex-end;
}

.cat-img-container .cat-img {
  height: 71px;
  width: 71px;
  object-fit: cover;
}

.img-container {
  height: 152px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.provider-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.no-cursor {
  cursor: default !important;
}

@media (max-width: 1024px) {
  .img-container {
    display: flex;
    justify-content: center;
  }
  .img-container .provider-img {
    height: 100px;
    width: 200px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/ProviderManagement/Style.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,sBAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,yBAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;AACJ;;AACA;EACI,eAAA;EACA,cAAA;EACA,gBAAA;EACA,0BAAA;AAEJ;;AAAA;EACI,qBAAA;EACA,UAAA;EACA,YAAA;AAGJ;;AADA;EACI,aAAA;EACA,yBAAA;AAIJ;;AADI;EACI,YAAA;EACA,WAAA;EACA,iBAAA;AAIR;;AAAA;EACI,aAAA;EACA,wCAAA;AAGJ;;AAAA;EACI,WAAA;EACA,YAAA;EACA,iBAAA;EACA,+BAAA;AAGJ;;AAAA;EACI,0BAAA;AAGJ;;AAAA;EACI;IACI,aAAA;IACA,uBAAA;EAGN;EAFM;IACI,aAAA;IACA,YAAA;EAIV;AACF","sourcesContent":[".profile-header-status {\n    background-color: #f7e5ec;\n    padding: 0.5rem 0.8rem;\n    border-radius: 30px;\n    font-size: 12px;\n    font-weight: 500;\n    border: 1px solid #000000;\n    min-width: 150px;\n    display: flex;\n    justify-content: center;\n}\n.profile-header-text {\n    font-size: 14px;\n    color: #000000;\n    font-weight: 600;\n    text-decoration: underline;\n}\n.hr-classes {\n    border-top-width: 2px;\n    opacity: 2;\n    color: black;\n}\n.user-management-details-option-container {\n    display: flex;\n    justify-content: flex-end;\n}\n.cat-img-container {\n    .cat-img {\n        height: 71px;\n        width: 71px;\n        object-fit: cover;\n    }\n}\n\n.img-container {\n    height: 152px;\n    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);\n}\n\n.provider-img {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n    transition: transform 0.3s ease;\n}\n\n.no-cursor {\n    cursor: default !important;\n}\n\n@media (max-width: 1024px) {\n    .img-container {\n        display: flex;\n        justify-content: center;\n        .provider-img {\n            height: 100px;\n            width: 200px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
