import React, { useState, useMemo, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Col, Row } from "reactstrap";

import { CustomButton } from "../../components/CustomButton";
import { SummaryCard } from "../../components/SummaryCard";
import { LabeledDataItem } from "../../components/LabeledDataItem";
import { LoaderFallbackUI } from "../../components/LoaderFallbackUI";
import { formatKey, hasData } from "../../utils/commonUtils";
import { transformAvailabilityData } from "../../utils/dateFormatUtils";
import { CategoryType } from "../../constants";

const CategoriesAndAvailibility = ({
    providerCategories,
    providerAvailibility,
    isLoading,
    isFetching
}) => {
    const { months, dates, timeRange } = useMemo(
        () => transformAvailabilityData(providerAvailibility),
        [providerAvailibility]
    );

    const [selectedMonth, setSelectedMonth] = useState(months?.[0]);

    useEffect(() => {
        // Ensure selectedMonth is reset when months change or if it is undefined
        if (months?.length > 0 && selectedMonth !== months[0]) {
            setSelectedMonth(months[0]);
        }
    }, [months]);

    if (isFetching || isLoading || !hasData(providerCategories) || !hasData(providerAvailibility)) {
        return (
            <div style={{ height: "70vh" }}>
                <LoaderFallbackUI isLoader={isFetching} className={"h-100"} />
            </div>
        );
    }

    return (
        <div className="mt-4">
            <Helmet>
                <title>Provider Management</title>
            </Helmet>
            <SummaryCard title="Services & Categories">
                {providerCategories.map((category, index) => (
                    <Row key={index} className="my-3">
                        <Col
                            xs={1}
                            sm={1}
                            md={1}
                            className="d-flex justify-content-center align-items-center cat-img-container"
                        >
                            <img
                                src={CategoryType.getDisplayTextKey(category?.category?.name)}
                                alt="Category"
                                className="img-fluid cat-img"
                            />
                        </Col>
                        <Col xs={10} sm={10} md={10}>
                            <div className="p-2">
                                <Row>
                                    <Col md={12}>
                                        <div className="my-1">
                                            <LabeledDataItem
                                                title="Category"
                                                desc={formatKey(category.category?.name)}
                                                titleColProps={{ xs: 4, md: 3 }}
                                                descColProps={{ xs: 8, md: 9 }}
                                            />
                                        </div>
                                        <div className="my-1">
                                            <LabeledDataItem
                                                title="SubCategories"
                                                desc={category?.providerCategorySubType
                                                    .map((subType) =>
                                                        formatKey(subType?.categorySubType?.name)
                                                    )
                                                    .join(", ")}
                                                titleColProps={{ xs: 4, md: 3 }}
                                                descColProps={{ xs: 8, md: 9 }}
                                            />
                                        </div>

                                        <LabeledDataItem
                                            title="Hourly Rate: "
                                            desc={`$${category?.rate.toFixed(2)}`}
                                            titleColProps={{ xs: 4, md: 3 }}
                                            descColProps={{ xs: 8, md: 9 }}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                ))}
            </SummaryCard>

            <SummaryCard title="Schedule & Availability">
                <Row className="my-3">
                    <Col md={12} sm={12} lg={12} xl={9} className="d-flex flex-wrap">
                        {months?.map((month, index) => (
                            <CustomButton
                                key={index}
                                title={month}
                                className={`me-3 custom-btn-md w-500 max-width-percent-modified fw-bold my-1 ${
                                    selectedMonth === month ? "active no-cursor" : ""
                                }`}
                                color="primary"
                                outline
                                onClick={() => setSelectedMonth(month)}
                            />
                        ))}
                    </Col>
                    <Col
                        sm={12}
                        md={12}
                        lg={12}
                        xl={3}
                        className="d-flex align-items-center justify-content-end justify-content-sm-start justify-content-xs-start my-1"
                    >
                        <strong className="me-2">Shift Timing:</strong> {timeRange[selectedMonth]}
                    </Col>
                </Row>

                <Row>
                    {dates[selectedMonth]?.map((date, index) => (
                        <div
                            key={index}
                            className="d-flex align-items-center justify-content-center m-2"
                            style={{
                                padding: "10px",
                                backgroundColor: "#d9e7ff",
                                borderRadius: "5px",
                                border: "1px solid #007bff",
                                width: "100px"
                            }}
                        >
                            {date}
                        </div>
                    ))}
                </Row>
            </SummaryCard>
        </div>
    );
};

export default CategoriesAndAvailibility;
