import React from "react";
import { LabeledDataItem } from "../../components/LabeledDataItem";
import { CardTitle, Col, Row } from "reactstrap";
import { SummaryCard } from "../../components/SummaryCard";

const HEADINGS = {
    OVER_ALL_RATING: "Overall Rating: ",
    RELIABILITY: "Reliability: ",
    TIMELINESS: "Timeliness: ",
    COMMUNICATION: "Communication: ",
    REVIEW: "Review: "
};

const profileData = {
    overallRating: 7,
    reliability: 12,
    timeliness: 5,
    communication: 25,
    review: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy"
};

const JobRatAndReviews = () => {
    return (
        <SummaryCard title="Rating and Reviews" titleClassName={"font-size-18 fw-bold"}>
            <CardTitle className="font-size-14 fw-bold">Seeker</CardTitle>

            <div style={{ color: "black" }} className="p-3">
                <Row>
                    <Col md={6}>
                        <Row className="">
                            <LabeledDataItem
                                title={HEADINGS.TIMELINESS}
                                desc={profileData.timeliness}
                                titleColProps={{ xs: 4, md: 4 }}
                                descColProps={{ xs: 8, md: 8 }}
                                rowClass="my-1"
                            />

                            <LabeledDataItem
                                title={HEADINGS.COMMUNICATION}
                                desc={profileData.communication}
                                titleColProps={{ xs: 4, md: 4 }}
                                descColProps={{ xs: 8, md: 8 }}
                                rowClass="my-1"
                            />
                        </Row>
                    </Col>

                    <Col md={6}>
                        <Row className="">
                            <LabeledDataItem
                                title={HEADINGS.OVER_ALL_RATING}
                                desc={profileData.overallRating}
                                titleColProps={{ xs: 4, md: 4 }}
                                descColProps={{ xs: 8, md: 8 }}
                                rowClass="my-1"
                            />

                            <LabeledDataItem
                                title={HEADINGS.RELIABILITY}
                                desc={profileData.reliability}
                                titleColProps={{ xs: 4, md: 4 }}
                                descColProps={{ xs: 8, md: 8 }}
                                rowClass="my-1"
                            />
                        </Row>
                    </Col>

                    <Col md={12}>
                        <Row className="">
                            <LabeledDataItem
                                title={HEADINGS.REVIEW}
                                desc={profileData.review}
                                titleColProps={{ xs: 2, md: 2 }}
                                descColProps={{ xs: 10, md: 10 }}
                                rowClass="my-1"
                            />
                        </Row>
                    </Col>
                </Row>
            </div>

            <CardTitle className="font-size-14 fw-bold">Provider</CardTitle>

            <div style={{ color: "black" }} className="p-3">
                <Row>
                    <Col md={6}>
                        <Row className="">
                            <LabeledDataItem
                                title={HEADINGS.TIMELINESS}
                                desc={profileData.timeliness}
                                titleColProps={{ xs: 4, md: 4 }}
                                descColProps={{ xs: 8, md: 8 }}
                                rowClass="my-1"
                            />

                            <LabeledDataItem
                                title={HEADINGS.COMMUNICATION}
                                desc={profileData.communication}
                                titleColProps={{ xs: 4, md: 4 }}
                                descColProps={{ xs: 8, md: 8 }}
                                rowClass="my-1"
                            />
                        </Row>
                    </Col>

                    <Col md={6}>
                        <Row className="">
                            <LabeledDataItem
                                title={HEADINGS.OVER_ALL_RATING}
                                desc={profileData.overallRating}
                                titleColProps={{ xs: 4, md: 4 }}
                                descColProps={{ xs: 8, md: 8 }}
                                rowClass="my-1"
                            />

                            <LabeledDataItem
                                title={HEADINGS.RELIABILITY}
                                desc={profileData.reliability}
                                titleColProps={{ xs: 4, md: 4 }}
                                descColProps={{ xs: 8, md: 8 }}
                                rowClass="my-1"
                            />
                        </Row>
                    </Col>

                    <Col md={12}>
                        <Row className="">
                            <LabeledDataItem
                                title={HEADINGS.REVIEW}
                                desc={profileData.review}
                                titleColProps={{ xs: 2, md: 2 }}
                                descColProps={{ xs: 10, md: 10 }}
                                rowClass="my-1"
                            />
                        </Row>
                    </Col>
                </Row>
            </div>
        </SummaryCard>
    );
};

export default JobRatAndReviews;
