import React from "react";
import { Row, Col } from "reactstrap";

import { SummaryCard } from "../../components/SummaryCard";
import { LabeledDataItem } from "../../components/LabeledDataItem";
import { monthYear } from "../../utils/dateUtils";
import { CategoryStatus, CategoryType } from "../../constants";

const HEADINGS = {
    JOB_TITLE: "Job Title:",
    JOB_TYPE: "Job Type:",
    START_DATE: "Start Date:",
    END_DATE: "End Date:",
    START_TIME: "Start Time:",
    END_TIME: "End Time:",
    HOURLY_RATE: "Hourly Rate:",
    LANGUAGE_PREFERENCES: "Language Preferences:",
    PICKUP_LOCATION: "Pick up Location:",
    DROPOFF_LOCATION: "Dropoff Location:",
    JOB_DESCRIPTION: "Job Description:",
    POSTED_DATE: "Posted Date:",
    EXPIRY_DATE: "Expiry Date:",
    TYPE: "Type:",
    CATEGORY: "Category:",
    SUBCATEGORY: "Subcategory:"
};
const timeFormatString = "ha";

const JobDetailsCard = ({ data }) => {
    return (
        <SummaryCard>
            <Row className="d-flex align-items-center ">
                <Col md={4}>
                    <div className="font-size-18 fw-bold p-2">Job Details</div>
                </Col>
                <Col md={4}></Col>
                <Col md={4}>
                    <div className="d-flex align-items-center justify-content-end">
                        <div
                            className={`cs-badge  me-4 ${CategoryStatus.getLabelClass(
                                data?.jobCategory
                            )}`}
                        >
                            {CategoryStatus.getDisplayTextKey(data?.jobCategory) || "-"}
                        </div>
                    </div>
                </Col>
            </Row>

            <div style={{ color: "black" }} className="px-4">
                <Row>
                    <Col md={6}>
                        <Row className="">
                            <LabeledDataItem
                                title={HEADINGS.CATEGORY}
                                desc={data?.category?.name || "-"}
                                titleColProps={{ xs: 5, sm: 5, md: 4, lg: 6 }}
                                descColProps={{ xs: 7, sm: 7, md: 8, md: 6 }}
                                rowClass="my-2"
                            />

                            <LabeledDataItem
                                title={HEADINGS.JOB_TITLE}
                                desc={data?.title || "-"}
                                titleColProps={{ xs: 5, sm: 5, md: 4, lg: 6 }}
                                descColProps={{ xs: 7, sm: 7, md: 8, md: 6 }}
                                rowClass="my-2"
                            />
                        </Row>
                    </Col>

                    <Col md={6}>
                        <Row className="">
                            <LabeledDataItem
                                title={HEADINGS.SUBCATEGORY}
                                desc={data.categorySubType?.name || "-"}
                                titleColProps={{ xs: 5, md: 5, lg: 6 }}
                                descColProps={{ xs: 7, md: 7, lg: 6 }}
                                rowClass="my-2"
                            />

                            <LabeledDataItem
                                title={HEADINGS.JOB_TYPE}
                                desc={data?.jobType || "-"}
                                titleColProps={{ xs: 5, md: 5, lg: 6 }}
                                descColProps={{ xs: 7, md: 7, lg: 6 }}
                                rowClass="my-2"
                            />
                        </Row>
                    </Col>

                    <Col md={6}>
                        <Row>
                            <LabeledDataItem
                                title={HEADINGS.START_DATE}
                                desc={monthYear("DD/MM/YY ", data?.startDate) || "-"}
                                titleColProps={{ xs: 5, sm: 5, md: 4, lg: 6 }}
                                descColProps={{ xs: 7, sm: 7, md: 8, md: 6 }}
                                rowClass="my-2"
                            />

                            <LabeledDataItem
                                title={HEADINGS.START_TIME}
                                desc={monthYear(timeFormatString, data?.startTime) || "-"}
                                titleColProps={{ xs: 5, sm: 5, md: 4, lg: 6 }}
                                descColProps={{ xs: 7, sm: 7, md: 8, md: 6 }}
                                rowClass="my-2"
                            />
                        </Row>
                    </Col>

                    <Col md={6}>
                        <Row>
                            <LabeledDataItem
                                title={HEADINGS.END_DATE}
                                desc={monthYear("DD/MM/YY ", data?.endDate) || "-"}
                                titleColProps={{ xs: 5, md: 5, lg: 6 }}
                                descColProps={{ xs: 7, md: 7, lg: 6 }}
                                rowClass="my-2"
                            />

                            <LabeledDataItem
                                title={HEADINGS.END_TIME}
                                desc={monthYear(timeFormatString, data?.endTime) || "-"}
                                titleColProps={{ xs: 5, md: 5, lg: 6 }}
                                descColProps={{ xs: 7, md: 7, lg: 6 }}
                                rowClass="my-2"
                            />
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Row>
                            <LabeledDataItem
                                title={HEADINGS.HOURLY_RATE}
                                desc={`$${data?.hourlyRate}/hr` || "-"}
                                titleColProps={{ xs: 5, sm: 5, md: 4, lg: 6 }}
                                descColProps={{ xs: 7, sm: 7, md: 8, md: 6 }}
                                rowClass="my-2"
                            />

                            <LabeledDataItem
                                title={HEADINGS.LANGUAGE_PREFERENCES}
                                desc={
                                    data?.languagePreferences
                                        ?.map((language) => language)
                                        ?.join(", ") || "-"
                                }
                                titleColProps={{ xs: 5, md: 5, lg: 6 }}
                                descColProps={{ xs: 7, md: 7, lg: 6 }}
                                rowClass="my-2"
                            />
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Row>
                            <LabeledDataItem
                                title={HEADINGS.POSTED_DATE}
                                desc={monthYear("DD/MM/YY ", data?.createdAt) || "-"}
                                titleColProps={{ xs: 5, sm: 5, md: 4, lg: 6 }}
                                descColProps={{ xs: 7, sm: 7, md: 8, md: 6 }}
                                rowClass="my-2"
                            />
                            <LabeledDataItem
                                title={HEADINGS.EXPIRY_DATE}
                                desc={monthYear("DD/MM/YY ", data?.expireAt) || "-"}
                                titleColProps={{ xs: 5, md: 5, lg: 6 }}
                                descColProps={{ xs: 7, md: 7, lg: 6 }}
                                rowClass="my-2"
                            />
                        </Row>
                    </Col>
                    {data.category?.name == CategoryType.Transportation && (
                        <Col md={6}>
                            <Row>
                                <LabeledDataItem
                                    title={HEADINGS.PICKUP_LOCATION}
                                    desc={data?.pickupLocation?.address || "-"}
                                    titleColProps={{ xs: 5, sm: 5, md: 4, lg: 6 }}
                                    descColProps={{ xs: 7, sm: 7, md: 8, md: 6 }}
                                    rowClass="my-2"
                                />
                                <LabeledDataItem
                                    title={HEADINGS.DROPOFF_LOCATION}
                                    desc={data?.dropOffLocation?.address || "-"}
                                    titleColProps={{ xs: 5, md: 5, lg: 6 }}
                                    descColProps={{ xs: 7, md: 7, lg: 6 }}
                                    rowClass="my-2"
                                />
                            </Row>
                        </Col>
                    )}

                    <Col md={6}>
                        <Row>
                            <LabeledDataItem
                                title={HEADINGS.JOB_DESCRIPTION}
                                desc={data?.description || "-"}
                                titleColProps={{ xs: 5, sm: 5, md: 4, lg: 6 }}
                                descColProps={{ xs: 7, sm: 7, md: 8, md: 6 }}
                                rowClass="my-2"
                            />
                        </Row>
                    </Col>
                </Row>
            </div>
        </SummaryCard>
    );
};

export default JobDetailsCard;
