import React from "react";
import { Row, Col, CardTitle } from "reactstrap";

import ProviderSummaryCard from "./ProviderSummaryCard";
import { SummaryCard } from "../../components/SummaryCard";

const ProviderSummary = ({ title, data }) => {
    return (
        <div className="border p-3 mb-3">
            <CardTitle tag="h5" className="font-weight-medium text-left">
                {title}
            </CardTitle>
            <Row>
                {data.map((item, index) => (
                    <Col sm={12} md={12} lg={6} xl={6} xxl={6} key={index}>
                        <SummaryCard>
                            <ProviderSummaryCard key={index} item={item} />
                        </SummaryCard>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default ProviderSummary;
