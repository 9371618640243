import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateRangePicker = ({ startDate, endDate, handleChangeStart, handleChangeEnd }) => {
    return (
        <div className={"d-flex justify-content-between gap-2 date-picker-contanier my-1"}>
            <DatePicker
                selected={startDate}
                onChange={handleChangeStart}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                placeholderText="Select start date"
                isClearable
            />
            <DatePicker
                selected={endDate}
                onChange={handleChangeEnd}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                placeholderText="Select end date"
                isClearable
            />
        </div>
    );
};

export default DateRangePicker;
