import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Label } from "reactstrap";
import { CustomModal } from "../../components/CustomModal";
import { useSelector } from "react-redux";
import { withRouter, Redirect, useHistory, Link } from "react-router-dom";
import { useFormik } from "formik";

import AuthWrapper from "./AuthWrapper";
import Headings from "./Headings";
import { TextInput } from "../../components/Input";
import { CustomButton } from "../../components/CustomButton";
import { APP_ROUTES, getParamsAttachedRoute } from "../../helpers/routeHelpers";
import { ForgotPassword } from "../../api/api.service";
import { PageTitles } from "../../constants";
import { forgotPassFormValidationSchema, initialValues } from "./FormValidations";

const ForgetPassword = ({}) => {
    const history = useHistory();
    let isAuthenticated = useSelector((state) => state.userAuth.isAuthenticated);

    const [isLoading, setIsLoading] = useState(false);
    const [holdToken, setHoldToken] = useState("");
    const [holdEmail, setHoldEmail] = useState("");
    const [showOtpModal, setShowOtpModal] = useState(false);

    const formik = useFormik({
        initialValues: initialValues.forgotPasswordForm,
        validationSchema: forgotPassFormValidationSchema,
        onSubmit: (values) => {
            handleEmailSubmit(values);
        }
    });

    const handleEmailSubmit = async (values) => {
        const { email } = values;
        try {
            setIsLoading(true);
            let res = await ForgotPassword({ email: email });
            // toast.success("An OTP has been send to your email address !");
            setHoldEmail(email);
            setHoldToken(res?.verificationToken);
            setShowOtpModal(true);
        } finally {
            setIsLoading(false);
        }
    };

    if (isAuthenticated) {
        return <Redirect to={APP_ROUTES.DASHBOARD} />;
    }

    return (
        <>
            <Helmet>
                <title>{PageTitles.FORGET_PASSWORD}</title>
            </Helmet>
            <AuthWrapper>
                <div className="w-100">
                    <div className="w-100 d-flex align-items-center justify-content-center">
                        {/* <div className="bv-forget-wrap"> */}
                        <Headings
                            title="FORGOT PASSWORD?"
                            subText="Enter your email address below, and we'll send you a One-Time Password (OTP) to help you reset it."
                        />
                    </div>

                    <div className="w-100 d-flex align-items-center justify-content-center mt-4">
                        <form className="w-50" onSubmit={formik.handleSubmit}>
                            {/* <h6 className="font-clr-theme-secondary mb-4 font-size-12 text-left p-0">
                                Enter your email to receive a one time password (OTP)
                            </h6> */}
                            <Label>Email</Label>

                            <div className="mb-4">
                                <TextInput
                                    placeholder="Enter Email"
                                    name="email"
                                    type="email"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                    className="hide-default-icon"
                                />
                                {formik.touched.email && formik.errors.email ? (
                                    <span className="error">{formik.errors.email}</span>
                                ) : null}
                            </div>

                            <CustomButton
                                loading={isLoading}
                                color="primary"
                                title="Get OTP"
                                className="w-100"
                            />
                            <div className="d-flex align-items-center justify-content-center my-4">
                                <Link to={APP_ROUTES.LOGIN}>
                                    <p className="clr-theme-primary fw-bold text-decoration-underline">
                                        {" "}
                                        Back to Login{" "}
                                    </p>
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </AuthWrapper>
            <CustomModal isOpen={showOtpModal} size="md">
                <div className="text-center">
                    <h2 className="clr-theme-primary">OTP Sent</h2>
                    <p className="my-4">
                        One Time Password has been sent to your provided <br /> email address.
                    </p>
                    <CustomButton
                        color="primary"
                        title="Continue"
                        className="custom-btn-sm"
                        onClick={() =>
                            history.push({
                                pathname: getParamsAttachedRoute(APP_ROUTES.FORGET_VERIFY_CODE, {
                                    tokenId: holdToken
                                }),
                                state: { email: holdEmail }
                            })
                        }
                    />
                </div>
            </CustomModal>
        </>
    );
};

export default withRouter(ForgetPassword);
