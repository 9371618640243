import React from "react";
import { Helmet } from "react-helmet";
import { Col, Row } from "reactstrap";
import { useParams } from "react-router-dom";

import { DataTable } from "../../components/DataTable";
import { SummaryCard } from "../../components/SummaryCard";
import { LabeledDataItem } from "../../components/LabeledDataItem";
import { getKey, MODULE_KEYS } from "../../api/api.keys";
import { GetRatingListing, GetSeekerById } from "../../api/api.service";
import { useLocalPaginatedRequest } from "../../hooks/useLocalPaginatedRequest";
import { Pagination } from "../../components/Pagination";
import { monthYear } from "../../utils/dateUtils";
import { renderStars } from "../../utils/ratingUtils";

const HEADINGS = {
    OVER_ALL_RATING: "Overall Rating: ",
    RELIABILITY: "Reliability: ",
    TIMELINESS: "Timeliness: ",
    COMMUNICATION: "Communication: "
};

const COLUMNS = {
    REVIEWER_NAME: "Reviewer Name",
    JOB_ID: "Job ID",
    OVERALL_RATING: "Overall Rating",
    TIMELINESS: "Timeliness",
    RELIABILITY: "Reliability",
    COMMUNICATION: "Communication",
    REVIEW: "Review",
    RATING_DATE: "Rating Date",
    ADDITIONAL_REVIEW: "Additional Review"
};

const SEEKER = "SEEKER";

const DetailRatingAndReviews = ({ ratingData }) => {
    const { seekerId } = useParams();

    const {
        isFetching: isRatingFetched,
        page,
        total,
        data: ratingListing,
        limit,
        searchText,
        handlePageClick,
        handleSearchChange,
        handleSortingChange
    } = useLocalPaginatedRequest({
        key: getKey([MODULE_KEYS.SEEKER_RATING_REVIEWS]),
        requestFn: GetRatingListing,
        dataKey: "data",
        totalKey: "total",
        params: {
            userType: SEEKER,
            receiverId: seekerId
        }
    });

    return (
        <div className="mt-4">
            <Helmet>
                <title>Seeker Management</title>
            </Helmet>

            <SummaryCard title="Rating and Reviews">
                <div className="font-size-12 fw-normal">
                    Based on {ratingData?.totalReviews} ratings
                </div>
                <div style={{ color: "black" }} className="py-2">
                    <Row>
                        <Col md={6}>
                            <LabeledDataItem
                                title={HEADINGS.OVER_ALL_RATING}
                                desc={
                                    <div>
                                        {ratingData?.avgRating?.toFixed(1)}{" "}
                                        {renderStars(ratingData?.avgRating)}{" "}
                                    </div>
                                }
                                titleColProps={{ xs: 5, md: 4, lg: 6 }}
                                descColProps={{ xs: 7, md: 8, lg: 6 }}
                            />
                        </Col>
                    </Row>
                </div>
                <div style={{ color: "black" }} className="py-2">
                    <Row>
                        <Col md={6}>
                            <LabeledDataItem
                                title={HEADINGS.RELIABILITY}
                                titleClass="fw-normal clr-theme-primary"
                                desc={
                                    <div>
                                        {ratingData?.avgReliabilityRating?.toFixed(1)}{" "}
                                        {renderStars(ratingData?.avgReliabilityRating)}{" "}
                                    </div>
                                }
                                titleColProps={{ xs: 5, md: 4, lg: 6 }}
                                descColProps={{ xs: 7, md: 8, lg: 6 }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <LabeledDataItem
                                title={HEADINGS.TIMELINESS}
                                titleClass="fw-normal clr-theme-primary"
                                desc={
                                    <div>
                                        {ratingData?.avgTimelinessRating?.toFixed(1)}{" "}
                                        {renderStars(ratingData?.avgTimelinessRating)}{" "}
                                    </div>
                                }
                                titleColProps={{ xs: 5, md: 4, lg: 6 }}
                                descColProps={{ xs: 7, md: 8, lg: 6 }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <LabeledDataItem
                                title={HEADINGS.COMMUNICATION}
                                titleClass="fw-normal clr-theme-primary"
                                desc={
                                    <div>
                                        {ratingData?.avgCommunicationRating?.toFixed(1)}{" "}
                                        {renderStars(ratingData?.avgCommunicationRating)}
                                    </div>
                                }
                                titleColProps={{ xs: 5, md: 5, lg: 6 }}
                                descColProps={{ xs: 7, md: 7, lg: 6 }}
                            />
                        </Col>
                    </Row>
                </div>

                <hr className={"my-3 hr-classes"} />
                <Row>
                    <Col>
                        <DataTable
                            tableContainerClass="mt-3"
                            loading={isRatingFetched}
                            data={ratingListing}
                            config={[
                                {
                                    title: COLUMNS.JOB_ID,
                                    render: (data) => <div className="">{data.id || "-"}</div>
                                },
                                {
                                    title: COLUMNS.REVIEWER_NAME,
                                    render: (data) => (
                                        <div className="">{data.receiver?.name || "-"}</div>
                                    )
                                },

                                {
                                    title: COLUMNS.OVERALL_RATING,
                                    render: (data) => (
                                        <div className="">
                                            {data.communicationRating +
                                                data?.reliabilityRating +
                                                data?.timelinessRating || "-"}
                                        </div>
                                    )
                                },
                                {
                                    title: COLUMNS.TIMELINESS,
                                    render: (data) => (
                                        <div className="">{data.timelinessRating || "-"}</div>
                                    )
                                },
                                {
                                    title: COLUMNS.RELIABILITY,
                                    render: (data) => (
                                        <div className="">{data.reliabilityRating || "-"}</div>
                                    )
                                },
                                {
                                    title: COLUMNS.COMMUNICATION,
                                    render: (data) => (
                                        <div className="">{data.communicationRating || "-"}</div>
                                    )
                                },
                                {
                                    title: COLUMNS.REVIEW,
                                    render: (data) => <div className="">{data.comment || "-"}</div>
                                },
                                {
                                    title: COLUMNS.RATING_DATE,
                                    render: (data) => (
                                        <div className="">
                                            {monthYear("DD/MM/YY ", data.createdAt) || "-"}
                                        </div>
                                    )
                                }
                                // {
                                //     title: COLUMNS.ADDITIONAL_REVIEW,
                                //     render: (data) => <div className="">{data.comment || "-"}</div>
                                // }
                            ]}
                        />
                    </Col>
                </Row>
            </SummaryCard>
            <Pagination
                rowLimit={limit}
                onPageClick={handlePageClick}
                currentPage={page}
                totalData={total}
                handleChange={(e) => handleSortingChange(e.target.value)}
            />
        </div>
    );
};

export default DetailRatingAndReviews;
