import { injectBaseConstantMethods } from "./BaseConstants";

const CATEGORY_STATUS = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
    QUICK_HIRE: "QUICK_HIRE",
    NORMAL_HIRE: "NORMAL_HIRE",
    DIRECT_HIRE: "DIRECT_HIRE"
};

const displayTextKeys = {
    [CATEGORY_STATUS.ACTIVE]: "Active",
    [CATEGORY_STATUS.INACTIVE]: "InActive",
    [CATEGORY_STATUS.QUICK_HIRE]: "QUICK HIRE",
    [CATEGORY_STATUS.NORMAL_HIRE]: "NORMAL HIRE",
    [CATEGORY_STATUS.DIRECT_HIRE]: "DIRECT HIRE"
};

const labelClass = {
    [CATEGORY_STATUS.ACTIVE]: "cs-badge-active",
    [CATEGORY_STATUS.INACTIVE]: "cs-badge-inactive",
    [CATEGORY_STATUS.QUICK_HIRE]: "cs-badge-quickhire",
    [CATEGORY_STATUS.NORMAL_HIRE]: "cs-badge-normalhire",
    [CATEGORY_STATUS.DIRECT_HIRE]: "cs-badge-directhire"
};

export default injectBaseConstantMethods(CATEGORY_STATUS, displayTextKeys, labelClass);
