import moment from "moment";

//Current Year.
export const currentYear = moment().year();

//Current Month
export const currentMonth = moment().month();

//current Month, year i.e -->>August, 2023
export const monthYear = (dateFormat, date) => {
    if (date) {
        return moment(date).format(dateFormat);
    }
    return;
};

// Create an Array of Years.
export const yearsArray = (startYear, endYear) => {
    const years = Array.from({ length: endYear - startYear + 1 }, (_, i) =>
        (startYear + i).toString()
    );
    return years;
};

// Create an Array of Months.
export const monthsArray = (dateFormat) => {
    const monthNames = [];
    for (let i = 0; i <= currentMonth; i++) {
        monthNames.push(moment().month(i).format(dateFormat));
    }
    return monthNames;
};

// Create an array of year options from start year to the current year
export const generateYearOptions = (startYear, labelKey = "label", valueKey = "value") => {
    const yearOptions = [];

    for (let year = currentYear; year >= startYear; year--) {
        const yearOption = {};
        yearOption[labelKey] = year.toString();
        yearOption[valueKey] = year.toString();
        yearOptions.push(yearOption);
    }
    return yearOptions;
};

//Create an array of month options from January to the current month with current year.
export const generateMonthOptions = (dateFormat) => {
    const currentMonth = moment().month();
    const monthOptions = [];
    for (let i = currentMonth; i >= 0; i--) {
        const label = `${moment().month(i).format(dateFormat)}, ${currentYear}`;
        const value = `${moment().month(i).format(dateFormat)}, ${currentYear}`;
        monthOptions.push({ label, value });
    }
    return monthOptions;
};
