import React from "react";
import { Helmet } from "react-helmet";
import { Col, Container, Row } from "reactstrap";
import { useParams } from "react-router-dom";

import ProviderSummary from "./ProviderSummary";
import { useRequestProcessor } from "../../hooks/useRequestProcessors";
import { getKey, MODULE_KEYS } from "../../api/api.keys";
import { GetPromotionStats } from "../../api/api.service";
import { LoaderFallbackUI } from "../../components/LoaderFallbackUI";
import { hasData, truncateString } from "../../utils/commonUtils";
import { useLocalPaginatedRequest } from "../../hooks/useLocalPaginatedRequest";
import { DataTable } from "../../components/DataTable";
import { monthYear } from "../../utils/dateUtils";
import { SearchBox } from "../../components/SearchBox";
import { Pagination } from "../../components/Pagination";

const COLUMNS = {
    CATEGORY: "Category",
    SUB_CATEGORY: "Sub Categories",
    RATE_TYPE: "Rate Type",
    RATE: "Rate",
    DESCRIPTION: "Description",
    AVAILIBILITY: "Availability",
    POSTED_ON: "Posted on"
};
const format = "Do MMMM YYYY";

const DetailPromotions = () => {
    const { providerId } = useParams();

    const {
        isFetching,
        isLoading,
        page,
        total,
        data,
        limit,
        searchText,
        handlePageClick,
        handleSearchChange,
        handleSortingChange
    } = useLocalPaginatedRequest({
        key: getKey([MODULE_KEYS.PROVIDER_PROMOTION_DETAIL]),
        requestFn: GetPromotionStats,
        dataKey: "data",
        totalKey: "total",
        params: {
            userId: providerId
        }
    });

    return (
        <div className="mt-4">
            <Helmet>
                <title>Provider Management</title>
            </Helmet>
            <Container fluid>
                <Row>
                    <Col sm="12" md="3" lg="3">
                        <SearchBox
                            className="me-3 w-100 my-1"
                            value={searchText}
                            onChange={handleSearchChange}
                        />
                    </Col>
                    <Col sm="12" md="6" lg="6"></Col>
                    <Col sm="12" md="3" lg="3"></Col>
                </Row>

                {/* <Row>
                <Col md={12}>
                    <ProviderSummary title="Promotions" data={data} />
                </Col>
            </Row> */}

                <Row>
                    <Col>
                        <DataTable
                            tableContainerClass="mt-3"
                            loading={isFetching}
                            data={data}
                            config={[
                                {
                                    title: COLUMNS.CATEGORY,
                                    render: (data) => (
                                        <div>{data?.promotionCategory?.category?.name || "-"}</div>
                                    )
                                },
                                {
                                    title: COLUMNS.SUB_CATEGORY,
                                    render: (data) => (
                                        <div>
                                            {data?.promotionCategory?.promotionCategorySubType
                                                ?.map((category) => category?.categorySubType?.name)
                                                .join(", ") || "-"}
                                        </div>
                                    )
                                },
                                {
                                    title: COLUMNS.RATE_TYPE,
                                    render: (data) => (
                                        <div>{truncateString(data?.rateType, 30) || "-"}</div>
                                    )
                                },
                                {
                                    title: COLUMNS.RATE,
                                    render: (data) => <div>{data?.rate || "-"}</div>
                                },
                                {
                                    title: COLUMNS.DESCRIPTION,
                                    render: (data) => (
                                        <div> {truncateString(data?.description, 30) || "-"}</div>
                                    )
                                },
                                {
                                    title: COLUMNS.AVAILIBILITY,
                                    render: (data) => (
                                        <div>
                                            {monthYear(format, data?.startDate)} to
                                            {monthYear(format, data?.endDate) || "-"}
                                        </div>
                                    )
                                },
                                {
                                    title: COLUMNS.POSTED_ON,
                                    render: (data) => (
                                        <div>
                                            {monthYear(
                                                format,
                                                data?.promotionCategory?.category?.createdAt
                                            )}
                                        </div>
                                    )
                                }
                            ]}
                        />
                    </Col>
                </Row>
                <Pagination
                    rowLimit={limit}
                    onPageClick={handlePageClick}
                    currentPage={page}
                    totalData={total}
                    handleChange={(e) => handleSortingChange(e.target.value)}
                />
            </Container>
        </div>
    );
};

export default DetailPromotions;
