import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";

import { SearchBox } from "../../components/SearchBox";
import { Pagination } from "../../components/Pagination";
import { DataTable } from "../../components/DataTable";
import { APP_ROUTES, getParamsAttachedRoute } from "../../helpers/routeHelpers";
import { GetCategories, UpdateCategoryById } from "../../api/api.service";
import { MODULE_KEYS, getKey } from "../../api/api.keys";
import { useRequestProcessor } from "../../hooks/useRequestProcessors";
import { useLocalPaginatedRequest } from "../../hooks/useLocalPaginatedRequest";
import { PageTitles, AdStatus } from "../../constants";
import { MODAL_CONTENT_CONFIG } from "./ModalContentConfig";
import viewIcon from "../../assets/images/app/common/listing-view-image.svg";
import { SwitchInput } from "../../components/Input";
import { SUCCESS, showToast } from "../../utils/toastUtils";
import { ConfirmationModal } from "../../components/CustomModal";
import { getStatus, findStatusChecked } from "./utils";
import { truncateString } from "../../utils/commonUtils";
import { useQueryClient } from "@tanstack/react-query";

const COLUMNS = {
    CATEGORY_ID: "Category Id",
    CATEGORY_NAME: "Category Name",
    ACTIVE_STATUS: "Active Status",
    ACTION: "Actions"
};

const recordUpdateSuccessMessage = "Status has been updated successfully";

const CategoryManagement = () => {
    const queryClient = useQueryClient();
    const history = useHistory();

    const [modalConfig, setModalConfig] = useState(null);
    const [isCategoryStatusUpdating, setIsCategoryStatusUpdating] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState({ status: "", id: "" });

    const {
        isFetching,
        page,
        handlePageClick,
        total,
        data,
        searchText,
        limit,
        handleSearchChange,
        handleSortingChange,
        refetch
    } = useLocalPaginatedRequest({
        key: getKey([MODULE_KEYS.CATEGORIES_MANAGEMENT]),
        requestFn: GetCategories,
        dataKey: "data",
        totalKey: "total",
        params: {}
    });

    let { useMutate } = useRequestProcessor();
    let { mutate } = useMutate("", getKey(), (data) =>
        UpdateCategoryById({ Id: selectedRecord?.id, bodyData: data })
    );

    const handleActionInit = (id, status) => {
        setSelectedRecord({ id, status });
        let statusToSet = getStatus(status);
        setModalConfig(MODAL_CONTENT_CONFIG[statusToSet]);
    };

    const handleActionCancellation = () => {
        setModalConfig(null);
    };

    // handling advertisement status update

    const handleCategoryStatusUpdate = () => {
        setIsCategoryStatusUpdating(true);
        let statusToSet = getStatus(selectedRecord.status);
        const bodyData = { status: statusToSet };
        // Api call here
        mutate(bodyData, {
            onSuccess: async (res) => {
                setIsCategoryStatusUpdating(false);
                showToast(SUCCESS, recordUpdateSuccessMessage);

                setModalConfig(null);
                refetch();
            },
            onError: (err) => {
                // showToast(ERROR, err?.Message)
                setIsCategoryStatusUpdating(false);
            }
        });
    };

    return (
        <div className="page-content">
            <Helmet>
                <title>{PageTitles.CATEGORY_MANAGEMENT}</title>
            </Helmet>
            <Container fluid>
                <Row>
                    <Col sm="12" md="3" lg="3">
                        <SearchBox
                            className="me-3 w-50"
                            value={searchText}
                            onChange={handleSearchChange}
                        />
                    </Col>
                    <Col sm="12" md="6" lg="6" className="my-2"></Col>
                </Row>

                <DataTable
                    tableContainerClass="mt-3"
                    loading={isFetching}
                    data={data}
                    config={[
                        {
                            title: COLUMNS.CATEGORY_ID,
                            render: (data) => <div>{data.id || "-"}</div>
                        },
                        {
                            title: COLUMNS.CATEGORY_NAME,
                            render: (data) => <div>{truncateString(data.name, 30) || "-"}</div>
                        },
                        {
                            title: COLUMNS.ACTIVE_STATUS,
                            render: (data) =>
                                (
                                    <div className="d-flex">
                                        <SwitchInput
                                            size="md"
                                            isChecked={findStatusChecked(data?.status)}
                                            onClick={() => handleActionInit(data?.id, data?.status)}
                                        />
                                    </div>
                                ) || "-"
                        },

                        {
                            title: COLUMNS.ACTION,
                            render: (data) => {
                                return (
                                    <div className="d-flex ">
                                        <img
                                            style={{
                                                filter: "brightness(0) saturate(100%) invert(58%) sepia(21%) saturate(708%) hue-rotate(283deg) brightness(83%) contrast(82%)"
                                            }}
                                            src={viewIcon}
                                            role="button"
                                            alt={"details icon"}
                                            onClick={() => {
                                                queryClient.invalidateQueries({
                                                    queryKey: [
                                                        MODULE_KEYS.CATEGORY_MANAGEMENT_DETAILS
                                                    ]
                                                });
                                                history.push(
                                                    getParamsAttachedRoute(
                                                        APP_ROUTES.CATEGORY_MANAGEMENT_DETAILS,
                                                        {
                                                            categoryId: data?.id
                                                        }
                                                    )
                                                );
                                            }}
                                        />
                                    </div>
                                );
                            }
                        }
                    ]}
                />
                <Pagination
                    rowLimit={limit}
                    onPageClick={handlePageClick}
                    currentPage={page}
                    totalData={total}
                    handleChange={(e) => handleSortingChange(e.target.value)}
                />
            </Container>
            <ConfirmationModal
                size="md"
                hasCustomConfig
                isOpen={modalConfig}
                customImageSrc={modalConfig?.imageSrc}
                customTitle={modalConfig?.modalTitle}
                customYesBtnText={modalConfig?.yesButtonText}
                customNoBtnText={modalConfig?.noButtonText}
                onYes={handleCategoryStatusUpdate}
                onNo={handleActionCancellation}
                yesLoading={isCategoryStatusUpdating}
            />
        </div>
    );
};
export default CategoryManagement;
