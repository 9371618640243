import { Roles } from "../constants";
import { APP_ROUTES } from "../helpers/routeHelpers";

let { Admin, SuperAdmin } = Roles;

const SIDEBAR_CONFIG = [
    {
        title: "Dashboard",
        url: APP_ROUTES.DASHBOARD,
        icon: "bx bx-grid-alt",
        menuImg: null,
        nestedMenu: null,
        permissions: [Admin]
    },
    {
        title: "Guest Users",
        url: APP_ROUTES.USER_MANAGEMENT,
        icon: "bx bx-user",
        menuImg: null,
        nestedMenu: null,
        permissions: [Admin]
    },
    {
        title: "Provider Management",
        url: APP_ROUTES.PROVIDER_MANAGEMENT,
        icon: "bx bx-file",
        menuImg: null,
        nestedMenu: null,
        permissions: [Admin]
    },
    {
        title: "Seeker Management",
        url: APP_ROUTES.SEEKER_MANAGEMENT,
        icon: "bx bx-spreadsheet",
        menuImg: null,
        nestedMenu: null,
        permissions: [Admin]
    },
    {
        title: "Category Management",
        url: APP_ROUTES.CATEGORY_MANAGEMENT,
        icon: "bx bxs-plus-square",
        menuImg: null,
        nestedMenu: null,
        permissions: [Admin]
    },
    {
        title: "Job Listing",
        url: APP_ROUTES.JOB_LISTING,
        icon: "bx bxs-plus-square",
        menuImg: null,
        nestedMenu: null,
        permissions: [Admin]
    },
    {
        title: "Account",
        url: APP_ROUTES.ACCOUNT,
        icon: "bx bx-user-circle",
        menuImg: null,
        nestedMenu: null,
        permissions: [Admin]
    }
];

export default SIDEBAR_CONFIG;
