import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Col, Container, Row } from "reactstrap";

import { Pagination } from "../../components/Pagination";
import { DataTable } from "../../components/DataTable";
import { GetUsers } from "../../api/api.service";
import { useLocalPaginatedRequest } from "../../hooks/useLocalPaginatedRequest";
import { PageTitles } from "../../constants";
import { MODULE_KEYS, getKey } from "../../api/api.keys";
import { CustomButton } from "../../components/CustomButton";
import { SearchBox } from "../../components/SearchBox";
import DateRangePicker from "./DateRangePicker";
import { monthYear } from "../../utils/dateUtils";

const COLUMN_HEADER = {
    S_NO: "S.No",
    EMAIL: "Email",
    SIGN_UP_DATE: "Sign-Up Date",
    USER_TYPE: "User Type",
    LAST_LOGIN: "Last Login"
};

const UserManagement = () => {
    //OUR VILLAGE
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const isDownloadEnabled = startDate && endDate;

    const {
        isFetching,
        page,
        total,
        data,
        searchText,
        refetch,
        limit,
        handlePageClick,
        handleSearchChange,
        handleSortingChange
    } = useLocalPaginatedRequest({
        key: getKey([MODULE_KEYS.USER_MANAGEMENT_LISTING]),
        requestFn: GetUsers,
        dataKey: "data",
        totalKey: "total",
        params: {}
    });

    //OUR VILLAGE
    const handleDateChange = (startDate, endDate) => {
        // Logic to fetch or filter data based on startDate and endDate
        console.log("Start Date:", startDate);
        console.log("End Date:", endDate);
    };

    const handleChangeStart = (date) => {
        setStartDate(date);
        if (date) handleDateChange(date, endDate);
    };

    const handleChangeEnd = (date) => {
        setEndDate(date);
        if (date) handleDateChange(startDate, date);
    };

    return (
        <div className="page-content">
            <Helmet>
                <title>{PageTitles.USER_MANAGEMENT}</title>
            </Helmet>
            <Container fluid>
                <Row>
                    <Col sm="12" md="3" lg="3">
                        <SearchBox
                            className="me-3 w-50 my-1"
                            value={searchText}
                            onChange={handleSearchChange}
                        />
                    </Col>
                    <Col sm="12" md="2" lg="2"></Col>
                    <Col sm="12" md="3" lg="3">
                        <div className="d-flex justify-content-end">
                            <CustomButton
                                title="Download"
                                color="primary"
                                className="custom-btn-md w-100 max-width-percent-modified btn-height my-1"
                                onClick={() => {}}
                                disabled={isDownloadEnabled == null}
                            />
                        </div>
                    </Col>
                    <Col sm="12" md="4" lg="4" align="right">
                        <DateRangePicker
                            startDate={startDate}
                            endDate={endDate}
                            handleChangeStart={handleChangeStart}
                            handleChangeEnd={handleChangeEnd}
                        />
                    </Col>
                </Row>

                <DataTable
                    tableContainerClass="mt-3"
                    loading={isFetching}
                    data={data}
                    config={[
                        {
                            title: COLUMN_HEADER.S_NO,
                            render: (data) => <div className="">{data.id || "-"}</div>
                        },
                        {
                            title: COLUMN_HEADER.EMAIL,
                            render: (data) => <div className="">{data.email || "-"}</div>
                        },
                        //verify this created at date
                        {
                            title: COLUMN_HEADER.SIGN_UP_DATE,
                            render: (data) => (
                                <div className="">
                                    {monthYear("DD/MM/YY ", data.createdAt) || "-"}
                                </div>
                            )
                        }
                    ]}
                />

                <Pagination
                    onPageClick={handlePageClick}
                    currentPage={page}
                    totalData={total}
                    handleChange={(e) => handleSortingChange(e.target.value)}
                    rowLimit={limit}
                />
            </Container>
        </div>
    );
};

export default UserManagement;
