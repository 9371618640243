import { useState } from "react";
import { useRequestProcessor } from "./useRequestProcessors";
import { getKey } from "../api/api.keys";
import Config from "../Config";

export const useLocalPaginatedRequest = ({
    id = "",
    key = [],
    requestFn = () => {},
    params = {},
    deps = [],
    searchKey = "Q",
    dataKey = "Data",
    totalKey = "Count"
}) => {
    const [page, setPage] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [limit, setLimit] = useState(Config.LIMIT);

    let { useFetch } = useRequestProcessor();

    let { data, isError, isFetching, refetch, isSuccess, isRefetching, isLoading, error } =
        useFetch(
            getKey([key, searchText, page, limit], params),

            async ({ signal }) =>
                await requestFn({
                    id: id,
                    params: {
                        limit: limit,
                        page: page,
                        ...(searchText && { [searchKey]: searchText }),
                        ...(Object.keys(params).length && params)
                    },
                    abortSignal: signal
                }),
            {
                keepPreviousData: true
            }
        );

    const fetchedData = isSuccess && data ? (dataKey in data ? data[dataKey] : data || []) : [];
    const fetchedTotal =
        isSuccess && data ? (totalKey in data ? data[totalKey] : data.length || 0) : 0;

    const handleSearchChange = (e) => {
        e.preventDefault();
        let searchText = e.target.value;
        setSearchText(searchText);
        setPage(1);
    };

    const handlePageClick = (pageNo) => {
        setPage(pageNo);
    };

    const handleSortingChange = (limit) => {
        setPage(1);
        setLimit(limit);
    };

    return {
        isError,
        isFetching,
        isSuccess,
        isRefetching,
        isLoading,
        error,
        page,
        searchText,
        limit,
        total: fetchedTotal,
        data: fetchedData,
        refetch,
        handleSearchChange,
        handlePageClick,
        handleSortingChange
    };
};
