import React from "react";

import { monthYear } from "../../utils/dateUtils";

// Date format
const format = "Do MMMM YYYY";

const ProviderSummaryCard = ({ item }) => {
    return (
        <>
            <div>
                <strong>Category:</strong> {item?.promotionCategory?.category?.name}
            </div>
            <div>
                <strong>Subcategories:</strong>{" "}
                {item?.promotionCategory?.promotionCategorySubType
                    ?.map((category) => category?.categorySubType?.name)
                    .join(", ")}
            </div>
            <div>
                <strong>Rate Type:</strong> {item?.rateType}
            </div>
            <div>
                <strong>Rate:</strong> {item?.rate}
            </div>
            <div>
                <strong>Description:</strong> {item?.description}
            </div>
            <div>
                <strong>Availability:</strong> {monthYear(format, item?.startDate)} to{" "}
                {monthYear(format, item?.endDate)}
            </div>
            <div>
                <strong>Posted on:</strong>{" "}
                {monthYear(format, item?.promotionCategory?.category?.createdAt)}
            </div>
        </>
    );
};

export default ProviderSummaryCard;
