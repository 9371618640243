export const FIELD_KEYS = {
    TOTAL_ACTIVE_JOBS: "totalActiveJobs",
    TOTAL_COMPLETED_JOBS: "totalCompletedJobs",
    TOTAL_CANCELLED_JOBS: "totalCancelledJobs",
    NORMAL_JOBS: "normalJobs",
    QUICK_HIRES: "quickHires",
    DIRECT_HIRES: "directHires",
    TOTAL_REGISTERED_USERS: "totalRegisteredUsers",
    TOTAL_PROVIDERS: "totalProviders",
    TOTAL_SEEKERS: "totalSeekers",
    TOTAL_GUEST_USERS: "totalGuestUsers",
    GUEST_PROVIDERS: "guestProviders",
    GUEST_SEEKERS: "guestSeekers"
};

export const FIELD_MAPPING = {
    [FIELD_KEYS.TOTAL_ACTIVE_JOBS]: "Total Active Jobs",
    [FIELD_KEYS.TOTAL_COMPLETED_JOBS]: "Total Completed Jobs",
    [FIELD_KEYS.TOTAL_CANCELLED_JOBS]: "Total Cancelled Jobs",
    [FIELD_KEYS.NORMAL_JOBS]: "Normal Jobs",
    [FIELD_KEYS.QUICK_HIRES]: "Quick Hires",
    [FIELD_KEYS.DIRECT_HIRES]: "Direct Hires",
    [FIELD_KEYS.TOTAL_REGISTERED_USERS]: "Total Registered Users",
    [FIELD_KEYS.TOTAL_PROVIDERS]: "Total Service Providers",
    [FIELD_KEYS.TOTAL_SEEKERS]: "Total Service Seekers",
    [FIELD_KEYS.TOTAL_GUEST_USERS]: "Total Guest Users",
    [FIELD_KEYS.GUEST_PROVIDERS]: "Guest Providers",
    [FIELD_KEYS.GUEST_SEEKERS]: "Guest Seekers"
};

export const mapUserDataToCards = (userData, jobData) => {
    let { totalNormalJobs, totalQuickJobs, totalDirectJobs } = userData?.activeJobs;
    let {
        totalNormalJobs: completedTotalNormalJobs,
        totalQuickJobs: completedTotalQuickJobs,
        totalDirectJobs: completedTotalDirectJobs
    } = userData?.completedJobs;

    let {
        totalNormalJobs: cancelledTotalNormalJobs,
        totalQuickJobs: cancelledTotalQuickJobs,
        totalDirectJobs: cancelledTotalDirectJobs
    } = userData?.cancelledJobs;

    return [
        {
            title: "Total Users",
            item: {
                totalGuestUsers: jobData?.totalGuestUsers,
                totalRegisteredUsers: jobData?.totalUsers,
                totalProviders: jobData?.totalProviderUsers,
                totalSeekers: jobData?.totalSeekerUsers
            }
        },
        {
            title: "Active Jobs",
            item: {
                totalActiveJobs: totalNormalJobs + totalQuickJobs + totalDirectJobs,
                totalNormalJobs: userData?.activeJobs?.totalNormalJobs,
                totalQuickJobs: userData?.activeJobs?.totalQuickJobs,
                totalDirectJobs: userData?.activeJobs?.totalDirectJobs
            }
        },
        {
            title: "Completed Jobs",
            item: {
                totalCompletedJobs:
                    completedTotalNormalJobs + completedTotalQuickJobs + completedTotalDirectJobs,
                totalNormalJobs: userData?.completedJobs?.totalNormalJobs,
                totalQuickJobs: userData?.completedJobs?.totalQuickJobs,
                totalDirectJobs: userData?.completedJobs?.totalDirectJobs
            }
        },
        {
            title: "Cancelled Jobs",
            item: {
                totalCancelledJobs:
                    cancelledTotalNormalJobs + cancelledTotalQuickJobs + cancelledTotalDirectJobs,
                totalNormalJobs: userData?.cancelledJobs?.totalNormalJobs,
                totalQuickJobs: userData?.cancelledJobs?.totalQuickJobs,
                totalDirectJobs: userData?.cancelledJobs?.totalDirectJobs
            }
        }
    ];
};
