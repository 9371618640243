import { injectBaseConstantMethods } from "./BaseConstants";
import careLogo from "../assets/images/app/our-village-app/care-category.svg";
import educattionLogo from "../assets/images/app/our-village-app/education-cat-logo.svg";
import homeLogo from "../assets/images/app/our-village-app/home-category-logo.svg";
import transportLogo from "../assets/images/app/our-village-app/transport-category-logo.svg";
import taskLogo from "../assets/images/app/our-village-app/task-errand-cat-logo.svg";

const CATEGORY_TYPE = {
    Care: "Care",
    Education: "Education",
    TaskAndErrand: "TasksAndErrands",
    Transportation: "Transportation",
    HomeAndMaintenance: "HomeMaintenance"
};

const displayTextKeys = {
    [CATEGORY_TYPE.Care]: careLogo,
    [CATEGORY_TYPE.Education]: educattionLogo,
    [CATEGORY_TYPE.TaskAndErrand]: taskLogo,
    [CATEGORY_TYPE.Transportation]: transportLogo,
    [CATEGORY_TYPE.HomeAndMaintenance]: homeLogo
};

export default injectBaseConstantMethods(CATEGORY_TYPE, displayTextKeys);
